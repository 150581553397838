@font-face {
  font-family: 'fontello';
  src: url('./easypixy-icons.woff2?48386444') format('woff2'),
  /* src: url('../font/easypixy-icons.woff2?48386444') format('woff2'), */
    url('./easypixy-icons.woff?48386444') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'fontello';
    src: url('./easypixy-icons.svg?48386444#fontello') format('svg');
  }
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  /* speak: never; */

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-easypixy_aggiungi_foto:before {
  content: '\e800';
} /* '' */
.icon-easypixy_aggiungi:before {
  content: '\e801';
} /* '' */
.icon-easypixy_blocca:before {
  content: '\e802';
} /* '' */
.icon-easypixy_bordo:before {
  content: '\e803';
} /* '' */
.icon-easypixy_carrello:before {
  content: '\e804';
} /* '' */
.icon-easypixy_carta:before {
  content: '\e805';
} /* '' */
.icon-easypixy_cestino:before {
  content: '\e806';
} /* '' */
.icon-easypixy_costo_spedizione:before {
  content: '\e807';
} /* '' */
.icon-easypixy_elimina:before {
  content: '\e808';
} /* '' */
.icon-easypixy_facebook:before {
  content: '\e809';
} /* '' */
.icon-easypixy_filtri:before {
  content: '\e80a';
} /* '' */
.icon-easypixy_formati_disponibili:before {
  content: '\e80b';
} /* '' */
.icon-easypixy_formato:before {
  content: '\e80c';
} /* '' */
.icon-easypixy_freccia_destra:before {
  content: '\e80d';
} /* '' */
.icon-easypixy_freccia_sinistra:before {
  content: '\e80e';
} /* '' */
.icon-easypixy_fronte:before {
  content: '\e80f';
} /* '' */
.icon-easypixy_google:before {
  content: '\e810';
} /* '' */
.icon-easypixy_home:before {
  content: '\e811';
} /* '' */
.icon-easypixy_impostazioni:before {
  content: '\e812';
} /* '' */
.icon-easypixy_indirizzi:before {
  content: '\e813';
} /* '' */
.icon-easypixy_info:before {
  content: '\e814';
} /* '' */
.icon-easypixy_informazioni:before {
  content: '\e815';
} /* '' */
.icon-easypixy_inquadra:before {
  content: '\e816';
} /* '' */
.icon-easypixy_instagram:before {
  content: '\e817';
} /* '' */
.icon-easypixy_layout:before {
  content: '\e818';
} /* '' */
.icon-easypixy_logout:before {
  content: '\e819';
} /* '' */
.icon-easypixy_materiali:before {
  content: '\e81a';
} /* '' */
.icon-easypixy_modifica_indirizzo:before {
  content: '\e81b';
} /* '' */
.icon-easypixy_modifica:before {
  content: '\e81c';
} /* '' */
.icon-easypixy_mostra:before {
  content: '\e81d';
} /* '' */
.icon-easypixy_nascondi:before {
  content: '\e81e';
} /* '' */
.icon-easypixy_notifiche:before {
  content: '\e81f';
} /* '' */
.icon-easypixy_opzioni_consegna:before {
  content: '\e820';
} /* '' */
.icon-easypixy_ordini:before {
  content: '\e821';
} /* '' */
.icon-easypixy_profilo:before {
  content: '\e822';
} /* '' */
.icon-easypixy_retro:before {
  content: '\e823';
} /* '' */
.icon-easypixy_ritiro_negozio:before {
  content: '\e824';
} /* '' */
.icon-easypixy_ruota:before {
  content: '\e825';
} /* '' */
.icon-easypixy_sblocca:before {
  content: '\e826';
} /* '' */
.icon-easypixy_sconti:before {
  content: '\e827';
} /* '' */
.icon-easypixy_sostituisci:before {
  content: '\e828';
} /* '' */
.icon-easypixy_stampante:before {
  content: '\e829';
} /* '' */
.icon-easypixy_sticker:before {
  content: '\e82a';
} /* '' */
.icon-easypixy_telefono:before {
  content: '\e82b';
} /* '' */
.icon-easypixy_testi:before {
  content: '\e82c';
} /* '' */
.icon-easypixy_tick:before {
  content: '\e82d';
} /* '' */
